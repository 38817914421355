.single-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .dx-card {
    width: 330px;
    padding: 40px;
    background-color: #f5f5f5;
    border-radius: 10px;

    .img-logo {
      display: block;
      margin: 0 auto;
      max-width: 200px;
      margin-bottom: 20px;
    }
    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
        text-align: left;
        margin-top: 15px;
      }

      .description {
        line-height: 18px;
        text-align: left;
        margin-top: 7px;
      }
      .btnLogin{
        width: 100%;
      }
    }
  }
}
